import {
    Grid,
    LoadingButton,
    api,
    decodedJwt,
    useSnackbar,
} from "@enerbit/base";
import { zodResolver } from "@hookform/resolvers/zod";
import PendingIcon from "@mui/icons-material/Pending";
import { type FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { mutate } from "swr";
import { formDiagnostic } from "../../form-builders";
import {
    calculateTotal,
    formatPrice,
    restoreTransformMaterials,
} from "../../helpers";
import type { IEmitDiagnostic } from "../../interfaces";
import { PostEmitDiagnosticSchema } from "../../schemas";
import { useEmissionStore, useTableStore } from "../../store";
import { useDiagnosticsStore } from "../../store/diagnostics.store";
import { FormItem } from "../dynamic-form";
import { EmissionDialog } from "./EmissionDialog";

interface Props {
    handleClose: () => void;
}

export const PostEmitDiagnosticForm: FC<Props> = ({ handleClose }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const { addedEntities } = useTableStore();

    const {
        materials,
        installation,
        createPreviousDiagnostic,
        field,
        material_quotation,
    } = useEmissionStore();

    const { page, size, selectedStatus } = useDiagnosticsStore();

    const total = calculateTotal(materials);
    const totalQuotation = calculateTotal(material_quotation);
    const hasNoCharge = material_quotation.some(
        (material) => !material.charge || material.charge === "0",
    );

    const getUserInfo = async () => {
        const res = await decodedJwt();
        return {
            name: res?.full_name ?? "",
            email: res?.name ?? "",
            operator_id: res?.sub ?? "",
        };
    };

    const methods = useForm<IEmitDiagnostic>({
        resolver: zodResolver(PostEmitDiagnosticSchema),
        defaultValues: {
            lead_id: addedEntities[0].id,
            file_path: "",
            form_data: {
                diagnostic: {
                    measurement_type:
                        field?.input_data?.form_data?.main_meter
                            ?.measurement_type ?? "",
                    comment_or: installation
                        ? total + totalQuotation <=
                          installation.totalInstallation
                            ? ""
                            : null
                        : null,
                    comment_bussiness: installation
                        ? total + totalQuotation <=
                          installation.totalInstallation
                            ? null
                            : ""
                        : "",
                    observation_work_order: installation
                        ? total <= installation.totalInstallation
                            ? ""
                            : null
                        : null,
                    main_meter:
                        materials.find(({ category }) =>
                            category?.toLowerCase()?.includes("medidor"),
                        )?.name ?? "",
                    optional_meter: "",
                    comunication: `SMARBIT - ${
                        materials.find(({ category }) =>
                            category?.toLowerCase()?.includes("sim"),
                        )?.name ?? ""
                    }`,
                    current_transformer:
                        field?.input_data.form_data.main_meter
                            ?.measurement_type === "semidirecta" ||
                        field?.input_data.form_data.main_meter
                            ?.measurement_type === "indirecta"
                            ? "Aplica"
                            : null,
                    voltage_transformer:
                        field?.input_data.form_data.main_meter
                            ?.measurement_type === "indirecta"
                            ? "Aplica"
                            : null,
                    box:
                        field?.input_data.form_data.electrical_boxes &&
                        field?.input_data.form_data.electrical_boxes?.length > 0
                            ? "Cumple"
                            : "No cumple",
                    control_wire:
                        materials.find(({ category }) =>
                            category?.toLowerCase()?.includes("conductor"),
                        )?.name ?? "",
                    of_type: "field",
                    status: hasNoCharge
                        ? "pending_quotation"
                        : installation
                          ? total + totalQuotation <=
                            installation.totalInstallation
                              ? "ok"
                              : "pending"
                          : "no",
                },
                materials_and_labor: {
                    materials: restoreTransformMaterials(materials),
                    total_price: formatPrice(total),
                },
                material_quotation: {
                    materials: restoreTransformMaterials(material_quotation),
                    total_price: formatPrice(totalQuotation),
                },
                emited_by: null,
            },
        },
        mode: "onChange",
    });

    const { watch, getValues, trigger, setValue } = methods;

    const status = watch("form_data.diagnostic.status");
    const measurementType = watch("form_data.diagnostic.measurement_type");

    useEffect(() => {
        if (
            measurementType === "semidirecta" ||
            measurementType === "indirecta"
        ) {
            setValue("form_data.diagnostic.current_transformer", "Aplica");
        } else {
            setValue("form_data.diagnostic.current_transformer", null);
        }
        if (measurementType === "indirecta") {
            setValue("form_data.diagnostic.voltage_transformer", "Aplica");
        } else {
            setValue("form_data.diagnostic.voltage_transformer", null);
        }
    }, [measurementType, setValue]);

    useEffect(() => {
        if (status === "ok") {
            setValue("form_data.diagnostic.comment_or", "");
            setValue("form_data.diagnostic.observation_work_order", "");
            setValue("form_data.diagnostic.comment_bussiness", null);
            setValue("form_data.diagnostic.comment_quotation", null);
        } else if (status === "pending_quotation" || status === "quoted") {
            setValue("form_data.diagnostic.comment_bussiness", null);
            setValue("form_data.diagnostic.comment_or", null);
            setValue("form_data.diagnostic.observation_work_order", null);
            setValue("form_data.diagnostic.comment_quotation", "");
        } else {
            setValue("form_data.diagnostic.comment_or", null);
            setValue("form_data.diagnostic.observation_work_order", null);
            setValue("form_data.diagnostic.comment_bussiness", "");
            setValue("form_data.diagnostic.comment_quotation", null);
        }
    }, [status, setValue]);

    const handleOpenDialog = async () => {
        if (await trigger()) setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleConfirmDialog = async () => {
        if (await trigger()) {
            const values = getValues();
            await onSubmit(values);
            handleCloseDialog();
        }
    };

    const onSubmit = async (data: IEmitDiagnostic) => {
        try {
            setIsLoading(true);
            const userInfo = await getUserInfo();
            const diagnostic: IEmitDiagnostic = {
                ...data,
                form_data: {
                    ...data.form_data,
                    emited_by: userInfo,
                },
            };
            const { data: id } = await api.post<string>(
                "/frontier-initial-diagnostic/emit-diagnostic",
                diagnostic,
            );
            await mutate(
                `/frontier-initial-diagnostic/diagnostics?page=${page}&size=${size}${
                    selectedStatus ? `&status=${selectedStatus}` : ""
                }`,
            );
            createPreviousDiagnostic({ ...diagnostic, id });
            enqueueSnackbar("Diagnóstico emitido con éxito.", {
                variant: "success",
            });
            handleClose();
        } catch (_error) {
            enqueueSnackbar(
                "No se pudo emitir el diagnóstico, inténtelo de nuevo.",
                {
                    variant: "error",
                },
            );
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={(e) => e.preventDefault()}>
                <Grid container spacing={3}>
                    {formDiagnostic.map((item, index) => {
                        const newItem = {
                            ...item,
                            name: `form_data.diagnostic.${item.name}`,
                        };

                        if (
                            newItem.name === "form_data.diagnostic.status" &&
                            hasNoCharge &&
                            material_quotation.length > 0
                        ) {
                            newItem.props = {
                                ...newItem.props,
                                disabled: true,
                            };
                            newItem.items = [
                                {
                                    text: "Pendiente de cotización",
                                    value: "pending_quotation",
                                    icon: PendingIcon,
                                    color: "secondary",
                                },
                            ];
                        }

                        return <FormItem {...newItem} key={index.toString()} />;
                    })}
                    {(measurementType === "semidirecta" ||
                        measurementType === "indirecta") && (
                        <Grid item xs={12}>
                            <FormItem
                                name="form_data.diagnostic.current_transformer"
                                label="Transformadores de corriente"
                                type="text"
                                sx={{ xs: 12, md: 6, lg: 4 }}
                            />
                        </Grid>
                    )}
                    {measurementType === "indirecta" && (
                        <Grid item xs={12}>
                            <FormItem
                                name="form_data.diagnostic.voltage_transformer"
                                label="Transformadores de tensión"
                                type="text"
                                sx={{ xs: 12, md: 6, lg: 4 }}
                            />
                        </Grid>
                    )}
                    {status === "ok" && (
                        <>
                            <Grid item xs={12}>
                                <FormItem
                                    name="form_data.diagnostic.comment_or"
                                    label="Comentarios para el OR"
                                    type="text-area"
                                    sx={{ xs: 12 }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormItem
                                    name="form_data.diagnostic.observation_work_order"
                                    label="Observación para orden de servicio"
                                    type="text-area"
                                    sx={{ xs: 12 }}
                                />
                            </Grid>
                        </>
                    )}
                    {status === "pending_quotation" && (
                        <Grid item xs={12}>
                            <FormItem
                                name="form_data.diagnostic.comment_quotation"
                                label="Comentarios sobre la cotización"
                                type="text-area"
                                sx={{ xs: 12 }}
                            />
                        </Grid>
                    )}
                    {(status === "no" || status === "pending") && (
                        <Grid item xs={12}>
                            <FormItem
                                name="form_data.diagnostic.comment_bussiness"
                                label="Comentarios comercial"
                                type="text-area"
                                sx={{ xs: 12 }}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <LoadingButton
                            loading={isLoading}
                            onClick={
                                status === "ok"
                                    ? handleOpenDialog
                                    : handleConfirmDialog
                            }
                            color="secondary"
                            variant="contained"
                            fullWidth
                        >
                            Crear
                        </LoadingButton>
                    </Grid>
                </Grid>
            </form>
            <EmissionDialog
                open={openDialog}
                onClose={handleCloseDialog}
                onConfirm={handleConfirmDialog}
            />
        </FormProvider>
    );
};
